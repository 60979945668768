// src/components/datagrid/mockProviders/kafkaProvider.ts
import { GridDataProvider, GridEvent } from '@/types/DataGridTypes';
import { KafkaCluster } from '@/types/kafka';

export class MockKafkaDataProvider implements GridDataProvider<KafkaCluster> {
  private subscribers: ((event: GridEvent<KafkaCluster>) => void)[] = [];
  private mockData: KafkaCluster[] = [
    {
      id: '1',
      name: 'prod-cluster-east',
      brokerCount: 6,
      topicCount: 245,
      status: 'Healthy'
    },
    {
      id: '2',
      name: 'prod-cluster-west',
      brokerCount: 6,
      topicCount: 189,
      status: 'Healthy'
    },
    {
      id: '3',
      name: 'staging-cluster',
      brokerCount: 3,
      topicCount: 45,
      status: 'Degraded'
    },
    {
      id: '4',
      name: 'dev-cluster',
      brokerCount: 3,
      topicCount: 28,
      status: 'Healthy'
    },
    {
      id: '5',
      name: 'test-cluster',
      brokerCount: 1,
      topicCount: 12,
      status: 'Unknown'
    }
  ];

  subscribe(callback: (event: GridEvent<KafkaCluster>) => void): () => void {
    this.subscribers.push(callback);
    return () => this.unsubscribe(callback);
  }

  unsubscribe(callback: (event: GridEvent<KafkaCluster>) => void): void {
    const index = this.subscribers.indexOf(callback);
    if (index > -1) {
      this.subscribers.splice(index, 1);
    }
  }

  async getData(): Promise<KafkaCluster[]> {
    return this.mockData;
  }

  async getInitialData(): Promise<KafkaCluster[]> {
    return this.mockData;
  }
}