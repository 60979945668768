// Core types for grid events and data
export type GridEventType = 'INSERT' | 'UPDATE' | 'DELETE';

// Add this near the top of your DataGridTypes.ts file
export interface BaseRow {
  id: string | number;
  [key: string]: any;
}

export interface GridEvent<T> {
  type: GridEventType;
  id: string | number;
  data: T;
  timestamp?: number;
}

// Column types
export interface BaseColumn {
  key: string;
  header: string;
  width?: number;
  sortable?: boolean;
  filterable?: boolean;
}

export interface FieldColumn extends BaseColumn {
  type: 'field';
  field: string;
  format?: (value: any) => string;
  route?: string;
}

export interface ImageColumn extends BaseColumn {
  type: 'image';
  field: string;
  route?: string;
  showCondition?: (row: any) => boolean;
  altField?: string;
}

// Union type for all possible column types
export type GridColumn = FieldColumn | ImageColumn;

// Type guard to check column types
export function isFieldColumn(column: GridColumn): column is FieldColumn {
  return column.type === 'field';
}

export function isImageColumn(column: GridColumn): column is ImageColumn {
  return column.type === 'image';
}

// Data provider interface
export interface GridDataProvider<T> {
  subscribe(callback: (event: GridEvent<T>) => void): () => void;
  unsubscribe(callback: (event: GridEvent<T>) => void): void;
  getData(): Promise<T[]>;
  getInitialData(): Promise<T[]>;
}

// Formatter utilities
export class GridFormatter {
  static formatDate(value: string | number, format: string = 'yyyy-MM-dd'): string {
    console.log(format)
    return new Date(value).toLocaleDateString();
  }

  static formatNumber(value: number, locale?: string, options?: Intl.NumberFormatOptions): string {
    return new Intl.NumberFormat(locale, options).format(value);
  }

  static formatCurrency(value: number, currency: string = 'USD'): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(value);
  }
}

// Abstract base class for data providers
export abstract class BaseGridDataProvider<T> implements GridDataProvider<T> {
  private subscribers: ((event: GridEvent<T>) => void)[] = [];

  subscribe(callback: (event: GridEvent<T>) => void): () => void {
    this.subscribers.push(callback);
    return () => this.unsubscribe(callback);
  }

  unsubscribe(callback: (event: GridEvent<T>) => void): void {
    const index = this.subscribers.indexOf(callback);
    if (index > -1) {
      this.subscribers.splice(index, 1);
    }
  }

  protected emit(event: GridEvent<T>): void {
    this.subscribers.forEach(callback => callback(event));
  }

  abstract getData(): Promise<T[]>;
  abstract getInitialData(): Promise<T[]>;
}

// Example implementation of a WebSocket data provider
export class WebSocketGridDataProvider<T> extends BaseGridDataProvider<T> {
  private ws: WebSocket | null = null;
  private data: Map<string | number, T> = new Map();

  constructor(private url: string) {
    super();
    this.connect();
  }

  private connect(): void {
    this.ws = new WebSocket(this.url);
    this.ws.onmessage = (event) => {
      const gridEvent: GridEvent<T> = JSON.parse(event.data);
      this.handleEvent(gridEvent);
    };
  }

  private handleEvent(event: GridEvent<T>): void {
    switch (event.type) {
      case 'INSERT':
      case 'UPDATE':
        this.data.set(event.id, event.data);
        break;
      case 'DELETE':
        this.data.delete(event.id);
        break;
    }
    this.emit(event);
  }

  async getData(): Promise<T[]> {
    return Array.from(this.data.values());
  }

  async getInitialData(): Promise<T[]> {
    return [];
  }
}