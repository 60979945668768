import React, { useState } from "react";
import { useTheme } from "@/providers/ThemeProvider";
import { Button } from "@/components/ui/button";
import {
  MoonIcon,
  SunIcon,
  User,
  Menu,
  X,
  Home,
  Boxes,
  Database,
  Users,
  Activity,
  FileText,
  Clock,
  ChevronRight,
} from "lucide-react";

interface MainLayoutProps {
  children: React.ReactNode;
}

interface NavItem {
  label: string;
  icon: React.ReactElement;
  href: string;
}

interface NavItemComponentProps {
  icon: React.ReactElement;
  label: string;
  href: string;
  isExpanded: boolean;
  isActive?: boolean;
}

const NavItemComponent = ({
  icon,
  label,
  href,
  isExpanded,
  isActive = false,
}: NavItemComponentProps) => {
  return (
    <a
      href={href}
      className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-all duration-200
        ${
          isActive
            ? "bg-primary/10 text-primary"
            : "text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-800"
        }`}
    >
      {React.cloneElement(icon, {
        className: `w-5 h-5 ${
          isActive ? "text-primary" : "text-gray-500 dark:text-gray-400"
        }`,
      })}
      {isExpanded && (
        <span
          className={`text-sm font-medium whitespace-nowrap overflow-hidden transition-all duration-200
          ${isActive ? "text-primary" : "text-gray-600 dark:text-gray-300"}`}
        >
          {label}
        </span>
      )}
    </a>
  );
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { theme, setTheme } = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const navItems: NavItem[] = [
    { label: "Home", icon: <Home />, href: "/" },
    { label: "Manifest", icon: <Boxes />, href: "/manifest" },
    { label: "Binding", icon: <Database />, href: "/streamlens" },
    { label: "Concepts", icon: <Users />, href: "/agents" },
    { label: "Explorer", icon: <Activity />, href: "/c" },
    { label: "Streams", icon: <FileText />, href: "/console" },
    { label: "History", icon: <Clock />, href: "/history" },
  ];

  return (
    <div className="min-h-screen bg-background flex dark:bg-gray-900">
      {/* Side Navigation */}
      <nav
        className={`fixed top-0 left-0 h-full bg-white dark:bg-gray-800 border-r border-gray-200 
        dark:border-gray-700 transition-all duration-300 shadow-sm z-20
        ${isExpanded ? "w-64" : "w-16"}`}
      >
        {/* Logo and Toggle */}
        <div className="h-14 flex items-center gap-3 px-3 border-b border-gray-200 dark:border-gray-700">
          {isExpanded && (
            <span className="text-lg font-semibold text-gray-900 dark:text-white">
              DUNKWARE
            </span>
          )}
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-auto"
          >
            {isExpanded ? (
              <X className="h-4 w-4" />
            ) : (
              <Menu className="h-4 w-4" />
            )}
          </Button>
        </div>

        {/* Nav Items */}
        <div className="p-3 space-y-1">
          {navItems.map((item) => (
            <NavItemComponent
              key={item.label}
              {...item}
              isExpanded={isExpanded}
              isActive={item.href === window.location.pathname}
            />
          ))}
        </div>
      </nav>

      {/* Main Content Area */}
      <div
        className={`flex-1 transition-all duration-300 ${
          isExpanded ? "ml-64" : "ml-16"
        }`}
      >
        {/* Header */}
        <header
          className="h-14 bg-white dark:bg-gray-800 border-b border-gray-200 
          dark:border-gray-700 flex items-center justify-end px-4 sticky top-0 z-10"
        >
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setTheme(theme === "light" ? "dark" : "light")}
            >
              {theme === "light" ? (
                <MoonIcon className="h-4 w-4" />
              ) : (
                <SunIcon className="h-4 w-4" />
              )}
            </Button>
            <Button variant="ghost" size="icon">
              <User className="h-4 w-4" />
            </Button>
          </div>
        </header>

        {/* Breadcrumb */}
        <nav className="bg-gray-50 dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center gap-2 px-4 py-2 overflow-x-auto">
            {navItems.map((item, index) => (
              <React.Fragment key={item.label}>
                <a
                  href={item.href}
                  className="flex items-center gap-1.5 text-sm text-gray-600 dark:text-gray-300 
                    hover:text-gray-900 dark:hover:text-white"
                >
                  {React.cloneElement(item.icon, { className: "w-4 h-4" })}
                  <span>{item.label}</span>
                </a>
                {index < navItems.length - 1 && (
                  <ChevronRight className="w-4 h-4 text-gray-400" />
                )}
              </React.Fragment>
            ))}
          </div>
        </nav>

        {/* Main Content */}
        <main className="p-6 bg-gray-50 dark:bg-gray-900 min-h-[calc(100vh-8.5rem)]">
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
