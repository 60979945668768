import React from 'react';

interface ConceptTypeCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

export const ConceptTypeCard: React.FC<ConceptTypeCardProps> = ({
  title,
  description,
  icon,
  onClick
}) => (
  <button 
    onClick={onClick}
    className="p-6 bg-white rounded-xl border hover:border-blue-200 hover:shadow-md transition-all w-full"
  >
    <div className="space-y-4">
      <div className="p-2 bg-blue-50 rounded-lg w-fit text-blue-600">
        {icon}
      </div>
      <div className="text-left">
        <h3 className="font-medium text-gray-900">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </div>
  </button>
);
