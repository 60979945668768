import { useState } from 'react';
import { User, Search, ChevronDown, Sun, ShoppingCart } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { Button } from '@/components/ui/button';
import { useTheme } from '@/providers/ThemeProvider';
import { Input } from '@/components/ui/input';


const MockHeader = () => {
  const { theme, setTheme } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const isWalmartTheme = theme === 'walmart';

  // Mock suggestions data
  const mockSuggestions = [
    'Analytics Dashboard',
    'API Documentation',
    'Authentication Setup',
    'Database Configuration'
  ];

  const filteredSuggestions = mockSuggestions.filter(item =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-background">
      {/* Header */}
      <header className={`${isWalmartTheme ? 'bg-[#0071DC]' : 'bg-primary'} shadow-md`}>
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="text-primary-foreground text-2xl font-bold">
              {isWalmartTheme ? 'DUNKWARE' : 'Cloud Logic'}
            </div>

            {/* Search Bar */}
            <div className="relative flex-1 max-w-xl mx-8">
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="text"
                  placeholder="Search..."
                  className="pl-8 w-full"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setShowSuggestions(true);
                  }}
                  onFocus={() => setShowSuggestions(true)}
                  onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                />
                {showSuggestions && searchQuery && (
                  <div className="absolute w-full mt-1 bg-popover rounded-md shadow-lg max-h-60 overflow-auto z-50">
                    {filteredSuggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 hover:bg-accent cursor-pointer text-popover-foreground"
                        onClick={() => {
                          setSearchQuery(suggestion);
                          setShowSuggestions(false);
                        }}
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Theme Toggle and User Profile */}
            <div className="flex items-center space-x-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setTheme(isWalmartTheme ? "light" : "walmart")}
                className="text-primary-foreground"
              >
                {isWalmartTheme ? (
                  <Sun className="h-5 w-5" />
                ) : (
                  <ShoppingCart className="h-5 w-5" />
                )}
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="text-primary-foreground">
                    <User className="h-5 w-5 mr-2" />
                    <span>Profile</span>
                    <ChevronDown className="h-4 w-4 ml-1" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-48">
                  <DropdownMenuItem>Profile</DropdownMenuItem>
                  <DropdownMenuItem>Logout</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>

          {/* Navigation */}
          <nav className="flex space-x-8 py-2">
            {['Console', 'Concepts', 'Explorer'].map((item) => (
              <Button
                key={item}
                variant="ghost"
                className={`text-primary-foreground hover:bg-primary-foreground/10`}
              >
                {item}
              </Button>
            ))}
          </nav>
        </div>
      </header>

      {/* Main Content Area */}
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-semibold text-foreground">
          Welcome to {isWalmartTheme ? 'Dunkware' : 'Cloud Logic'}
        </h1>
        <p className="mt-4 text-muted-foreground">
          This is the main content area of your application.
        </p>
      </main>
    </div>
  );
};

export default MockHeader;