import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// Fix the import path to match your project structure
import { useAuth } from '../../hooks/useAuth';  // or '@/hooks/useAuth'
import { SplashScreen } from '@/components/SplashScreen';

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredRole?: string;
  requiredPermission?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ 
  children, 
  requiredRole,
  requiredPermission 
}) => {
  const { user, loading, isAuthenticated } = useAuth();
  const location = useLocation();

  console.log('PrivateRoute - Current State:', {
    isAuthenticated,
    loading,
    userDetails: {
      id: user?.id,
      username: user?.username,
      roles: user?.roleNames,
      permissions: user?.permissionNames
    },
    currentPath: location.pathname,
    requiredRole,
    requiredPermission
  });

  if (loading) {
    console.log('PrivateRoute - Still loading auth state');
    return <SplashScreen />;
  }

  // Modified to only check isAuthenticated first
  if (!isAuthenticated) {
    console.log('PrivateRoute - Not authenticated, redirecting to login', {
      from: location.pathname,
      hasUser: !!user
    });
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Only check user-related permissions if there's a user object and specific requirements
  if (user) {
    // Check for required role
    if (requiredRole && (!user.roleNames || !user.roleNames.includes(requiredRole))) {
      console.log('PrivateRoute - User lacks required role:', {
        userRoles: user.roleNames,
        requiredRole
      });
      return <Navigate to="/unauthorized" replace />;
    }

    // Check for required permission
    if (requiredPermission && (!user.permissionNames || !user.permissionNames.includes(requiredPermission))) {
      console.log('PrivateRoute - User lacks required permission:', {
        userPermissions: user.permissionNames,
        requiredPermission
      });
      return <Navigate to="/unauthorized" replace />;
    }

    console.log('PrivateRoute - Auth successful, rendering protected content', {
      path: location.pathname,
      username: user.username,
      roles: user.roleNames,
      permissions: user.permissionNames
    });
  } else {
    console.log('PrivateRoute - Authenticated but no user object, rendering content without role checks');
  }

  return <>{children}</>;
};

// Helper hook to check permissions/roles anywhere in the app
export const useAuthorization = () => {
  const { user } = useAuth();

  return {
    hasRole: (role: string) => user?.roleNames?.includes(role) ?? false,
    hasPermission: (permission: string) => user?.permissionNames?.includes(permission) ?? false,
    hasAnyRole: (roles: string[]) => roles.some(role => user?.roleNames?.includes(role)) ?? false,
    hasAnyPermission: (permissions: string[]) => 
      permissions.some(permission => user?.permissionNames?.includes(permission)) ?? false
  };
};