// src/App.tsx
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@/providers/ThemeProvider";

// Layout Components

import { PrivateRoute } from "@/components/PrivateRoute";

// Authentication Pages
import { LoginPage } from "@/pages/LoginPage";
import { UserDashboard } from "@/pages/UserDashboard";
import AdminConsole from "./pages/AdminConsole";

// Core Features
import { ConceptCanvas } from "./modules/concept/canvas/ConceptCanvas";
import WorkflowEditor from "./modules/concept/board/starter/WorkflowEditor";

import WelcomePage from "./modules/concept/canvas/pages/WelcomePage";

// Tools and Utilities
import JSONAnalyzerDashboard from "./components/streamlens/widgets/JsonInspector";
import CloudConsole from "./components/mock/CloudConsole";
import StreamConcepts from "./components/mock/StreamConcepts";
import CardStackDemo from "./components/cardstack/CardStackDemo";
import MockHeader from "./components/mock/MockkHeader";
import MinimalLayout from "./layouts/MinimalLayout";
import Manifest from "./pages/Manifest";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider defaultTheme="walmart" storageKey="dunkware-theme">
        <div className="min-h-screen bg-background font-sans antialiased">
          <div className="relative flex min-h-screen flex-col">
            <div className="flex-1">
              <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<LoginPage />} />

                {/* Protected Routes */}
                <Route
                  path="/*"
                  element={
                    <MinimalLayout>
                      <Routes>
                        {/* Dashboard Routes */}
                        <Route
                          path="dashboard"
                          element={
                            <PrivateRoute>
                              <UserDashboard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="admin/*"
                          element={
                            <PrivateRoute requiredRole="ADMIN">
                              <AdminConsole />
                            </PrivateRoute>
                          }
                        />

                        {/* Concept & Workflow Routes */}
                        <Route path="canvas/*" element={<ConceptCanvas />} />
                        <Route path="editor" element={<WorkflowEditor />} />
                        <Route path="monitor" element={<WelcomePage />} />
                        <Route path="kafka" element={<Manifest />} />

                        {/* Tool Routes */}
                        <Route
                          path="streamlens/*"
                          element={<JSONAnalyzerDashboard />}
                        />
                        <Route path="console" element={<CloudConsole />} />
                        <Route path="concepts" element={<StreamConcepts />} />

                        {/* Development/Testing Routes */}
                        <Route path="mockhead/*" element={<MockHeader />} />
                        <Route path="stack" element={<CardStackDemo />} />
                      </Routes>
                    </MinimalLayout>
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
