// src/pages/UserDashboard.tsx
import React, { useMemo } from 'react';
import { MainLayout } from '@/layouts/MainLayout';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { SplashScreen } from '@/components/SplashScreen';
import { DataGrid } from '@/components/datagrid/DataGrid';
import { GridColumn } from '@/types/DataGridTypes';
import { KafkaCluster } from '@/types/kafka';
import { MockKafkaDataProvider } from '@/components/mock/MockKafkaDataProvider';
import LogoMarquee from '@/components/mock/LogoMarque';


export const UserDashboard: React.FC = () => {
  // Initialize the data provider
  const kafkaProvider = useMemo(() => new MockKafkaDataProvider(), []);

  // Define columns
  const columns: GridColumn[] = [
    {
      type: 'field',
      key: 'name',
      field: 'name',
      header: 'Name',
      sortable: true,
    },
    {
      type: 'field',
      key: 'brokerCount',
      field: 'brokerCount',
      header: 'Broker Count',
      sortable: true,
      format: (value: number) => value.toString(),
    },
    {
      type: 'field',
      key: 'topicCount',
      field: 'topicCount',
      header: 'Topics',
      sortable: true,
      format: (value: number) => value.toString(),
    },
    {
      type: 'field',
      key: 'status',
      field: 'status',
      header: 'Status',
      sortable: true,
      format: (value: string): string => {
        const colorMap: Record<string, string> = {
          'Healthy': 'bg-green-500',
          'Degraded': 'bg-yellow-500',
          'Critical': 'bg-red-500',
          'Unknown': 'bg-gray-500'
        };
        return `<span class="${colorMap[value]} text-white">${value}</span>`;
      },
    }
  ];

  return (
    <MainLayout>
      <SplashScreen />
      <div className="space-y-6">
        <Card className="w-full">
          <CardHeader>
            <CardTitle className="text-2xl">Welcome to Cloud Logic</CardTitle>
            <CardDescription>
              Your central hub for managing cloud resources and services
            </CardDescription>
          </CardHeader>
          <LogoMarquee></LogoMarquee>
          <CardContent>
            <p className="text-muted-foreground mb-6">
              Cloud Logic provides a powerful platform for managing your cloud infrastructure. 
              Use the navigation menu to explore concepts and search entities across your environment.
            </p>

            <div className="space-y-6">
              <CardTitle className="text-xl">Kafka Clusters</CardTitle>
              <DataGrid<KafkaCluster>
                columns={columns}
                dataProvider={kafkaProvider}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </MainLayout>
  );
};