// src/modules/concepts/canvas/routes/index.tsx
import { Routes, Route, Navigate } from 'react-router-dom';
import { ConceptCanvasLayout } from '../components/layout/ConceptCanvasLayout';
import { WelcomePage } from '../pages/WelcomePage';
import { CreateConceptPage } from '../pages/CreateConceptPage';

export const ConceptCanvasRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<ConceptCanvasLayout />}>
        <Route index element={<WelcomePage />} />
        <Route path="create" element={<CreateConceptPage />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Route>
    </Routes>
  );
};