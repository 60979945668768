import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Home, Plus, Settings } from 'lucide-react';
import { NavItem } from '../navigation';

export const ConceptCanvasLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white border-b shadow-sm">
        <div className="px-4 py-3 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <h1 className="text-xl font-semibold text-gray-800">
              <span className="text-blue-600">DUNKWARE</span>
              <span className="mx-2 text-gray-300">|</span>
              
            </h1>
          </div>
          <div className="flex items-center space-x-4">
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <Settings className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>
      </header>

      <div className="flex h-[calc(100vh-64px)]">
        {/* Left Navigation */}
        <nav className="w-48 border-r bg-white">
          <div className="p-4 space-y-2">
            <NavItem 
              icon={<Home className="w-5 h-5" />} 
              label="Home" 
              active={location.pathname === '/canvas'} 
              onClick={() => navigate('/canvas')}
            />
            <NavItem 
              icon={<Plus className="w-5 h-5" />} 
              label="Create" 
              active={location.pathname === '/canvas/create'}
              onClick={() => navigate('/canvas/create')}
            />
          </div>
        </nav>

        {/* Main Content Area */}
        <main className="flex-1 overflow-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};