import React, { useState } from 'react';
import { GitBranch, Play,  Package, Brain, ListTree, AlertTriangle, Rocket } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
    
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";


// Type definitions
interface Signal {
  name: string;
  logic: string;
}

interface DerivedField {
  name: string;
  type: string;
  logic: string;
}

interface BacktestInfo {
  coverage: string;
  timespan: string;
  status: 'passed' | 'warning' | 'failed';
}

interface DeploymentInfo {
  status: 'active' | 'staging' | 'inactive';
  version: string;
  instances: number;
}

interface Concept {
  id: string;
  name: string;
  description: string;
  dependencies: string[];
  signals: Signal[];
  derivedFields: DerivedField[];
  backtest: BacktestInfo;
  deployment: DeploymentInfo;
}

const StreamConcepts: React.FC = () => {
  const [concepts] = useState<Concept[]>([
    {
      id: 'volatility-detection',
      name: 'Volatility Detection',
      description: 'Advanced volatility detection across multiple time windows',
      dependencies: ['price_stream', 'volume_stream'],
      signals: [
        {
          name: 'rolling_volatility',
          logic: 'rolling_std(price, window=20) / rolling_mean(price, window=20)'
        },
        {
          name: 'volume_spike',
          logic: 'volume > rolling_mean(volume, window=50) * 2'
        }
      ],
      derivedFields: [
        {
          name: 'volatility_regime',
          type: 'enum',
          logic: 'classify_volatility(rolling_volatility, volume_spike)'
        }
      ],
      backtest: {
        coverage: '98%',
        timespan: '90d',
        status: 'passed'
      },
      deployment: {
        status: 'active',
        version: '1.2.3',
        instances: 3
      }
    },
    {
      id: 'market-sentiment',
      name: 'Market Sentiment',
      description: 'Multi-factor market sentiment analysis',
      dependencies: ['news_stream', 'social_stream', 'price_stream'],
      signals: [
        {
          name: 'sentiment_score',
          logic: 'weighted_average([news_sentiment, social_sentiment])'
        }
      ],
      derivedFields: [
        {
          name: 'market_mood',
          type: 'enum',
          logic: 'classify_sentiment(sentiment_score, price_momentum)'
        }
      ],
      backtest: {
        coverage: '95%',
        timespan: '30d',
        status: 'warning'
      },
      deployment: {
        status: 'staging',
        version: '2.0.0-rc1',
        instances: 1
      }
    }
  ]);

  return (
    <div className="p-6 max-w-7xl mx-auto space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold"> Concept Cloud</h1>
          <p className="text-gray-500">Composable Temporal Logic Units</p>
        </div>
        <div className="flex gap-2">
          <Button variant="outline" className="flex items-center gap-2">
            <GitBranch className="w-4 h-4" />
            Fork Concept
          </Button>
          <Button className="flex items-center gap-2">
            <Brain className="w-4 h-4" />
            New Concept
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {concepts.map(concept => (
          <Card key={concept.id} className="shadow-lg">
            <CardHeader>
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center gap-2">
                    <CardTitle className="text-xl">{concept.name}</CardTitle>
                    <Badge variant={concept.deployment.status === 'active' ? 'default' : 'secondary'}>
                      v{concept.deployment.version}
                    </Badge>
                  </div>
                  <CardDescription>{concept.description}</CardDescription>
                </div>
                <div className="flex gap-2">
                  <Button variant="outline" size="sm">
                    <Package className="w-4 h-4 mr-1" />
                    Deploy
                  </Button>
                  <Button size="sm">
                    <Play className="w-4 h-4 mr-1" />
                    Test
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <Tabs defaultValue="design">
                <TabsList className="grid w-full grid-cols-4">
                  <TabsTrigger value="design">
                    <Brain className="w-4 h-4 mr-2" />
                    Design
                  </TabsTrigger>
                  <TabsTrigger value="dependencies">
                    <ListTree className="w-4 h-4 mr-2" />
                    Dependencies
                  </TabsTrigger>
                  <TabsTrigger value="backtest">
                    <AlertTriangle className="w-4 h-4 mr-2" />
                    Backtest
                  </TabsTrigger>
                  <TabsTrigger value="deployment">
                    <Rocket className="w-4 h-4 mr-2" />
                    Deployment
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="design">
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-sm font-medium mb-2">Signals</h3>
                      {concept.signals.map((signal, idx) => (
                        <div key={idx} className="mb-2 p-3 bg-gray-50 rounded-lg">
                          <div className="flex justify-between items-center mb-1">
                            <span className="font-medium">{signal.name}</span>
                          </div>
                          <code className="text-sm block bg-gray-100 p-2 rounded">
                            {signal.logic}
                          </code>
                        </div>
                      ))}
                    </div>
                    <div>
                      <h3 className="text-sm font-medium mb-2">Derived Fields</h3>
                      {concept.derivedFields.map((field, idx) => (
                        <div key={idx} className="mb-2 p-3 bg-gray-50 rounded-lg">
                          <div className="flex justify-between items-center mb-1">
                            <span className="font-medium">{field.name}</span>
                            <Badge variant="outline">{field.type}</Badge>
                          </div>
                          <code className="text-sm block bg-gray-100 p-2 rounded">
                            {field.logic}
                          </code>
                        </div>
                      ))}
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="dependencies">
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-2">
                      {concept.dependencies.map((dep, idx) => (
                        <div key={idx} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                          <span>{dep}</span>
                          <Badge variant="outline">required</Badge>
                        </div>
                      ))}
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="backtest">
                  <div className="space-y-4">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">Coverage</div>
                        <div className="text-2xl font-bold">{concept.backtest.coverage}</div>
                      </div>
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">Timespan</div>
                        <div className="text-2xl font-bold">{concept.backtest.timespan}</div>
                      </div>
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">Status</div>
                        <div className="text-2xl font-bold">{concept.backtest.status}</div>
                      </div>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="deployment">
                  <div className="space-y-4">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">Status</div>
                        <div className="text-2xl font-bold">{concept.deployment.status}</div>
                      </div>
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">Version</div>
                        <div className="text-2xl font-bold">{concept.deployment.version}</div>
                      </div>
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-500 mb-1">Instances</div>
                        <div className="text-2xl font-bold">{concept.deployment.instances}</div>
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default StreamConcepts;