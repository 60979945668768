import React from 'react';

interface NavItemProps {
  icon: React.ReactNode;
  label: string;
  active?: boolean;
  onClick?: () => void;
}

export const NavItem: React.FC<NavItemProps> = ({
  icon,
  label,
  active = false,
  onClick
}) => (
  <button
    onClick={onClick}
    className={`w-full flex items-center space-x-3 px-4 py-2 rounded-lg text-sm
      ${active 
        ? 'bg-blue-50 text-blue-600' 
        : 'text-gray-600 hover:bg-gray-50'}`}
  >
    {icon}
    <span>{label}</span>
  </button>
);