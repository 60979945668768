import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Brain, ChevronLeft, ChevronRight } from 'lucide-react';
import { QuickActionCard, ConceptCard } from '../components/cards';
import { Card } from '@/components/ui/card';


const ConceptCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Extended mock concept data
  const concepts = [
    {
      title: "Market Sentiment",
      type: "Analysis Concept",
      description: "Multi-factor market sentiment analysis",
      version: "1.2.0"
    },
    {
      title: "Portfolio Optimizer",
      type: "Strategy Concept",
      description: "Risk-adjusted portfolio optimization engine",
      version: "2.0.1"
    },
    {
      title: "News Analyzer",
      type: "Data Concept",
      description: "Real-time financial news sentiment tracker",
      version: "1.0.3"
    },
    {
      title: "Risk Calculator",
      type: "Analysis Concept",
      description: "Advanced risk metrics computation",
      version: "1.1.0"
    },
    {
      title: "Trading Signal Generator",
      type: "Strategy Concept",
      description: "Multi-timeframe trading signals",
      version: "1.3.2"
    },
    {
      title: "Performance Analytics",
      type: "Analysis Concept",
      description: "Comprehensive performance metrics suite",
      version: "2.1.0"
    }
  ];

  const visibleCards = 3;

  useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handlePrevious = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => (prev === 0 ? concepts.length - visibleCards : prev - 1));
      setTimeout(() => setIsAnimating(false), 300);
    }
  };

  const handleNext = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => (prev === concepts.length - visibleCards ? 0 : prev + 1));
      setTimeout(() => setIsAnimating(false), 300);
    }
  };

  return (
    <Card className="p-6">
      <div className="relative">
        <button
          onClick={handlePrevious}
          className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors"
          disabled={isAnimating}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors"
          disabled={isAnimating}
        >
          <ChevronRight className="w-6 h-6" />
        </button>

        <div className="overflow-hidden px-8">
          <div
            className="flex transition-transform duration-300 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * (100 / visibleCards)}%)`,
            }}
          >
            {concepts.map((concept, index) => (
              <div
                key={index}
                className="w-1/3 flex-shrink-0 px-2 transition-all duration-300"
                style={{
                  transform: isAnimating ? 'scale(0.95)' : 'scale(1)',
                }}
              >
                <ConceptCard
                  title={concept.title}
                  type={concept.type}
                  description={concept.description}
                  version={concept.version}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center mt-4 gap-2">
          {Array.from({ length: concepts.length - visibleCards + 1 }).map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full transition-colors ${
                currentIndex === index ? 'bg-blue-500' : 'bg-gray-300'
              }`}
              onClick={() => {
                if (!isAnimating) {
                  setIsAnimating(true);
                  setCurrentIndex(index);
                  setTimeout(() => setIsAnimating(false), 300);
                }
              }}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export const WelcomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6 max-w-7xl mx-auto space-y-8">
      {/* Welcome Section */}
      <div className="space-y-2">
        <h2 className="text-2xl font-bold text-gray-900">Welcome to Concept Cloud</h2>
        <p className="text-gray-500">Make your concepts come to life. </p>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <QuickActionCard
          title="Create New Concept"
          description="Start building a new concept from scratch"
          icon={<Plus className="w-6 h-6" />}
          onClick={() => navigate('create')}
        />
        <QuickActionCard
          title="Browse Templates"
          description="Use pre-built concept templates"
          icon={<Brain className="w-6 h-6" />}
          onClick={() => navigate('templates')}
        />
      </div>

      {/* Featured Concepts Carousel */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">Featured Concepts</h3>
          <button className="text-sm text-blue-600 hover:text-blue-700 font-medium">
            View All
          </button>
        </div>
        <ConceptCarousel />
      </div>

      {/* Recent Concepts Grid */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">Recent Concepts</h3>
          <button className="text-sm text-blue-600 hover:text-blue-700 font-medium">
            View All
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <ConceptCard
            title="Market Sentiment"
            type="Analysis Concept"
            description="Multi-factor market sentiment analysis"
            version="1.2.0"
          />
          <ConceptCard
            title="Portfolio Optimizer"
            type="Strategy Concept"
            description="Risk-adjusted portfolio optimization engine"
            version="2.0.1"
          />
          <ConceptCard
            title="New Concept"
            type="Create New"
            isCreateNew
            onClick={() => navigate('create')}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;