// src/components/SplashScreen/SplashScreen.tsx
import React, { useEffect, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { Loader2 } from "lucide-react";
import { Navigate } from 'react-router-dom';

export const SplashScreen: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { loading, user } = useAuth();

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  // If we're done loading and the splash screen is no longer visible
  if (!loading && !isVisible) {
    // Redirect based on auth state
    if (user) {
      return user.roleNames.includes('ADMIN') ? 
        <Navigate to="/admin" replace /> : 
        <Navigate to="/dashboard" replace />;
    } else {
      return <Navigate to="/login" replace />;
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-background">
      <div className="text-center space-y-4">
        <div className="flex items-center justify-center text-2xl font-bold mb-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2 h-8 w-8"
          >
            <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
          </svg>
          DUNKWARE
        </div>
        <Loader2 className="h-8 w-8 animate-spin mx-auto" />
      </div>
    </div>
  );
};