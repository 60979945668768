import React from "react";
import { Layout, Menu } from "antd";

const { Header, Sider, Content } = Layout;

// Define menu item types for better type safety
type MenuItem = {
  key: string;
  label: string;
};

const AdminConsole: React.FC = () => {
  // Menu items for navigation
  const menuItems: MenuItem[] = [
    { key: "1", label: "Dashboard" },
    { key: "2", label: "Stream Lens" },
    { key: "3", label: "Integrations" },
    { key: "4", label: "Settings" },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ color: "white", textAlign: "center", fontSize: "18px" }}>
        Concept Cloud Admin
      </Header>
      <Layout>
        <Sider collapsible>
          <Menu theme="dark" mode="inline">
            {menuItems.map((item) => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout>
          <Content
            style={{ margin: "24px", background: "#fff", padding: "24px" }}
          >
            {/* Dynamic content goes here */}
            Welcome to Concept Cloud Admin Console
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminConsole;
