
import googleCloudLogo from './GoogleCloud.png';
import mysqlLogo from './cassylogo.png';
import azureLogo from './azure.png';
import confluentLogo from './confluent.png';

const LogoMarquee = () => {
  const logos = [
    {
      id: 1,
      src: googleCloudLogo,
      alt: "Google Cloud",
      className: "h-12"
    },
    {
      id: 2,
      src: mysqlLogo,
      alt: "MySQL",
      className: "h-12"
    },
    {
      id: 3,
      src: azureLogo,
      alt: "Azure",
      className: "h-12"
    },
    {
      id: 4,
      src: confluentLogo,
      alt: "Confluent",
      className: "h-12"
    }
  ];

  return (
    <div className="relative w-full overflow-hidden bg-white py-4">
      {/* Added wrapper for continuous animation */}
      <div className="inline-flex animate-scroll">
        {/* First set of logos */}
        <div className="flex">
          {logos.map((logo) => (
            <div
              key={logo.id}
              className="mx-8 flex items-center justify-center"
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className={`${logo.className} object-contain`}
              />
            </div>
          ))}
        </div>
        {/* Duplicate set for seamless loop */}
        <div className="flex">
          {logos.map((logo) => (
            <div
              key={`duplicate-${logo.id}`}
              className="mx-8 flex items-center justify-center"
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className={`${logo.className} object-contain`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoMarquee;