import React, { createContext, useContext, useState } from 'react';
import { ConceptCanvasConfig } from '../types';

interface ConceptCanvasContextType {
  config: ConceptCanvasConfig;
  setConfig: (config: ConceptCanvasConfig) => void;
}

const ConceptCanvasContext = createContext<ConceptCanvasContextType | undefined>(undefined);

export const ConceptCanvasProvider: React.FC<{
  children: React.ReactNode;
  initialConfig?: ConceptCanvasConfig;
}> = ({ children, initialConfig = {} }) => {
  const [config, setConfig] = useState<ConceptCanvasConfig>(initialConfig);

  return (
    <ConceptCanvasContext.Provider value={{ config, setConfig }}>
      {children}
    </ConceptCanvasContext.Provider>
  );
};

export const useConceptCanvas = () => {
  const context = useContext(ConceptCanvasContext);
  if (!context) {
    throw new Error('useConceptCanvas must be used within ConceptCanvasProvider');
  }
  return context;
};