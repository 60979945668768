import { useRef } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface Layer {
  title: string;
  description: string;
  image: string;
}

const layers: Layer[] = [
  {
    title: "Concept/Logic Layer",
    description: "Model and compute expressions to create reusable concepts.",
    image: "/api/placeholder/400/320", // Using placeholder for demo
  },
  {
    title: "Data Processing Layer",
    description: "Transform and process data streams with powerful pipelines.",
    image: "/api/placeholder/400/320",
  },
  {
    title: "Integration Layer",
    description: "Connect and orchestrate services seamlessly.",
    image: "/api/placeholder/400/320",
  },
];

const CardStackDemo = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 340;
      const scrollLeft = scrollContainerRef.current.scrollLeft;
      scrollContainerRef.current.scrollTo({
        left:
          direction === "left"
            ? scrollLeft - scrollAmount
            : scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="space-y-6 text-center mb-12">
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-blue-400">
            Dunkware Layers
          </h1>
          <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
            A comprehensive stack of technologies working in harmony to power
            your applications
          </p>
        </div>

        <div className="relative">
          <Button
            variant="outline"
            size="icon"
            onClick={() => scroll("left")}
            className="absolute -left-4 top-1/2 -translate-y-1/2 rounded-full z-10 bg-background/80 backdrop-blur-sm"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>

          <Button
            variant="outline"
            size="icon"
            onClick={() => scroll("right")}
            className="absolute -right-4 top-1/2 -translate-y-1/2 rounded-full z-10 bg-background/80 backdrop-blur-sm"
          >
            <ChevronRight className="h-4 w-4" />
          </Button>

          <div
            ref={scrollContainerRef}
            className="flex overflow-x-auto gap-6 pb-8 snap-x snap-mandatory scrollbar-hide px-4"
          >
            {layers.map((layer, index) => (
              <motion.div
                key={index}
                className="flex-none"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.1,
                  ease: "easeOut",
                }}
              >
                <Card
                  className="w-80 bg-card hover:bg-accent/5 rounded-xl overflow-hidden 
                               border border-border/50 backdrop-blur-sm
                               transform transition-all duration-300 hover:scale-105 
                               hover:shadow-lg snap-center"
                >
                  <motion.div
                    className="relative h-48 w-full overflow-hidden"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={layer.image}
                      alt={layer.title}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.currentTarget.src = "/api/placeholder/400/320";
                      }}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-background/80 to-transparent" />
                    <div className="absolute bottom-0 left-0 right-0 p-4">
                      <div className="h-1 w-16 bg-primary rounded-full" />
                    </div>
                  </motion.div>

                  <div className="p-6 space-y-4">
                    <div className="space-y-2">
                      <h2 className="text-xl font-semibold text-foreground">
                        {layer.title}
                      </h2>
                      <p className="text-sm text-muted-foreground line-clamp-3">
                        {layer.description}
                      </p>
                    </div>

                    <div className="flex items-center justify-between pt-4 border-t border-border/50">
                      <span className="text-xs text-muted-foreground">
                        Layer {String(index + 1).padStart(2, "0")}
                      </span>
                      <div className="flex items-center gap-2">
                        <span className="w-2 h-2 rounded-full bg-primary animate-pulse" />
                        <span className="text-xs text-muted-foreground">
                          Active
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </motion.div>
            ))}
          </div>

          {/* Scroll Progress Indicator */}
          <div className="mt-8 flex justify-center gap-2">
            {layers.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  if (scrollContainerRef.current) {
                    const cardWidth = 340;
                    scrollContainerRef.current.scrollTo({
                      left: cardWidth * index,
                      behavior: "smooth",
                    });
                  }
                }}
                className="w-2 h-2 rounded-full transition-all duration-300"
                style={{
                  backgroundColor:
                    index === 0 ? "var(--primary)" : "var(--border)",
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardStackDemo;
