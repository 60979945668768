import {  useEffect, useState } from 'react';
import { GridColumn, GridDataProvider, isFieldColumn, isImageColumn } from '@/types/DataGridTypes';   
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { MoreVertical } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

// Base interface that requires an id field
interface BaseRow {
  id: string | number;
  [key: string]: any;
}

interface DataGridProps<T extends BaseRow> {
  columns: GridColumn[];
  dataProvider: GridDataProvider<T>;
}

export const DataGrid = <T extends BaseRow>({ columns, dataProvider }: DataGridProps<T>) => {
  const [data, setData] = useState<T[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    // Initial data load
    dataProvider.getInitialData().then(setData);

    // Subscribe to updates
    const unsubscribe = dataProvider.subscribe((event) => {
      setData(currentData => {
        const newData = [...currentData];
        const index = newData.findIndex(item => item.id === event.id);
        
        switch (event.type) {
          case 'INSERT':
            if (index === -1) newData.push(event.data);
            break;
          case 'UPDATE':
            if (index !== -1) newData[index] = event.data;
            break;
          case 'DELETE':
            if (index !== -1) newData.splice(index, 1);
            break;
        }
        return newData;
      });
    });

    return unsubscribe;
  }, [dataProvider]);

  // Transform our GridColumns into TanStack table columns
  const tableColumns: ColumnDef<T>[] = columns.map(column => {
    if (isFieldColumn(column)) {
      return {
        accessorKey: column.field,
        header: column.header,
        cell: ({ getValue }) => {
          const value = getValue();
          return column.format ? column.format(value) : value;
        }
      };
    }
    
    if (isImageColumn(column)) {
      return {
        accessorKey: column.field,
        header: column.header,
        cell: ({ row }) => {
          if (column.showCondition && !column.showCondition(row.original)) {
            return null;
          }
          const value = row.getValue(column.field);
          // Add type assertion for image URL
          const imageUrl = value as string;
          return (
            <img 
              src={imageUrl} 
              alt={column.altField ? (row.getValue(column.altField) as string) : ''} 
              className="w-8 h-8 rounded-full"
            />
          );
        }
      };
    }
    
    // This case should never happen due to type system
    throw new Error(`Unknown column type: ${(column as any).type}`);
  });

  const table = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <div className="w-full">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHead key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableHead>
              ))}
              <TableHead>Actions</TableHead>
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map(row => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </TableCell>
              ))}
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <MoreVertical className="h-4 w-4" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem>View Details</DropdownMenuItem>
                    <DropdownMenuItem>Edit</DropdownMenuItem>
                    <DropdownMenuItem className="text-red-600">Delete</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataGrid;