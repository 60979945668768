import React from 'react';
import { Plus } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';


interface ConceptCardProps {
  title: string;
  type: string;
  description?: string;
  version?: string;
  isCreateNew?: boolean;
  onClick?: () => void;
}

export const ConceptCard: React.FC<ConceptCardProps> = ({
  title,
  type,
  description,
  version,
  isCreateNew = false,
  onClick
}) => (
  <Card className={isCreateNew ? 'border-dashed bg-transparent' : ''}>
    <CardContent className="p-6">
      {isCreateNew ? (
        <button 
          onClick={onClick}
          className="w-full h-full flex flex-col items-center justify-center space-y-2 text-gray-500 hover:text-gray-700"
        >
          <Plus className="w-8 h-8" />
          <span className="font-medium">Create New Concept</span>
        </button>
      ) : (
        <>
          <div className="flex justify-between items-start mb-4">
            <div>
              <div className="flex items-center gap-2">
                <h3 className="font-medium text-gray-900">{title}</h3>
                {version && (
                  <span className="px-2 py-1 text-xs bg-blue-50 text-blue-600 rounded-full">
                    v{version}
                  </span>
                )}
              </div>
              <span className="text-sm text-gray-500">{type}</span>
            </div>
          </div>
          {description && <p className="text-sm text-gray-600">{description}</p>}
        </>
      )}
    </CardContent>
  </Card>
);