import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  MouseEvent as ReactMouseEvent,
} from "react";
import {
  Database,
  Factory,
  Cpu,
  Share2,
  Boxes,
  FileJson,
  Waves,
  Server,
  HardDrive,
  MessageSquare,
  Cloud,
  Activity,
  Zap,
  Pencil,
  Trash2,
} from "lucide-react";

// Type Definitions
type NodeCategory = "source" | "decoder" | "processor" | "sink";

// Connection Rules
const CONNECTION_RULES: Record<NodeCategory, NodeCategory[]> = {
  source: [], // Sources can't connect to anything (they are only connected to)
  decoder: ["source"], // Decoders can connect to sources
  processor: ["decoder"], // Processors can connect to decoders
  sink: ["processor", "decoder"], // Sinks can connect to processors and decoders
};

// Interfaces
interface NodeType {
  id: string;
  category: NodeCategory;
  type: string;
  name: string;
  icon: React.ReactNode;
}

interface Node {
  id: string;
  type: string;
  category: NodeCategory;
  name: string;
  x: number;
  y: number;
  placeholder?: string;
}

interface MenuItem {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
  danger?: boolean;
}

interface ContextMenuProps {
  children: React.ReactNode;
  items: MenuItem[];
}

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

interface ConnectionPort {
  id: string;
  nodeId: string;
  type: "input" | "output";
  x: number;
  y: number;
  category: NodeCategory;
}

interface Connection {
  id: string;
  sourceId: string;
  targetId: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}

interface DraggingConnection {
  sourcePortId: string;
  sourceCategory: NodeCategory;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

// Dialog Component
const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-background/80 backdrop-blur-sm transition-opacity"
        onClick={onClose}
      />

      {/* Dialog Positioning */}
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        {/* Dialog Content */}
        <div className="relative transform overflow-hidden rounded-lg bg-card text-left shadow-xl transition-all w-full max-w-lg border border-border">
          <div className="bg-card px-4 pt-5 pb-4 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium leading-6 text-foreground">
                {title}
              </h3>
              <button
                onClick={onClose}
                className="rounded-md text-muted-foreground hover:text-foreground focus:outline-none focus:ring-2 focus:ring-ring"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-2 text-foreground">{children}</div>
          </div>
          {footer && (
            <div className="bg-muted/50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border-t border-border">
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Context Menu Component
const ContextMenu: React.FC<ContextMenuProps> = ({ children, items }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const handleContextMenu = useCallback(
    (event: globalThis.MouseEvent) => {
      event.preventDefault();
      const x = Math.min(event.clientX, window.innerWidth - 160);
      const y = Math.min(event.clientY, window.innerHeight - items.length * 36);
      setPosition({ x, y });
      setIsVisible(true);
    },
    [items.length]
  );

  const handleClick = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [handleClick, handleContextMenu]);

  const handleItemClick = (
    e: ReactMouseEvent<HTMLButtonElement>,
    onClick: () => void
  ) => {
    e.stopPropagation();
    onClick();
    setIsVisible(false);
  };

  return (
    <div className="inline-block">
      {children}
      {isVisible && (
        <div
          className="fixed z-50 w-40 bg-white rounded-lg shadow-lg border border-gray-200 py-1 animate-in fade-in zoom-in duration-200"
          style={{ left: position.x, top: position.y }}
          onClick={(e: ReactMouseEvent) => e.stopPropagation()}
        >
          {items.map((item, index) => (
            <button
              key={index}
              onClick={(e) => handleItemClick(e, item.onClick)}
              className={`
                w-full px-3 py-2 text-sm text-left flex items-center space-x-2
                hover:bg-gray-100 transition-colors
                ${
                  item.danger ? "text-red-600 hover:bg-red-50" : "text-gray-700"
                }
              `}
            >
              {item.icon && <span className="w-4 h-4">{item.icon}</span>}
              <span>{item.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

// Node Types Definition
const nodeTypes: NodeType[] = [
  // Data Sources
  {
    id: "kafka-source",
    category: "source",
    type: "kafka",
    name: "Kafka Topic",
    icon: <MessageSquare className="w-5 h-5" />,
  },
  {
    id: "redis-source",
    category: "source",
    type: "redis",
    name: "Redis Topic",
    icon: <Database className="w-5 h-5" />,
  },
  {
    id: "rest-source",
    category: "source",
    type: "rest",
    name: "REST API",
    icon: <Share2 className="w-5 h-5" />,
  },
  {
    id: "dunkware-entity",
    category: "source",
    type: "dunkware-entity",
    name: "Dunkware Entity",
    icon: <Activity className="w-5 h-5" />,
  },
  {
    id: "dunkware-signal",
    category: "source",
    type: "dunkware-signal",
    name: "Dunkware Signal",
    icon: <Zap className="w-5 h-5" />,
  },
  // Decoders
  {
    id: "json-decoder",
    category: "decoder",
    type: "json",
    name: "JSON Decoder",
    icon: <FileJson className="w-5 h-5" />,
  },
  {
    id: "avro-decoder",
    category: "decoder",
    type: "avro",
    name: "Avro Decoder",
    icon: <Boxes className="w-5 h-5" />,
  },
  {
    id: "protobuf-decoder",
    category: "decoder",
    type: "protobuf",
    name: "Protocol Buffers",
    icon: <Factory className="w-5 h-5" />,
  },
  // Processors
  {
    id: "entity-binder",
    category: "processor",
    type: "entityBinder",
    name: "Entity Binder",
    icon: <Cpu className="w-5 h-5" />,
  },
  {
    id: "item-factory",
    category: "processor",
    type: "itemFactory",
    name: "Item Factory",
    icon: <Waves className="w-5 h-5" />,
  },
  // Sinks
  {
    id: "kafka-sink",
    category: "sink",
    type: "kafka",
    name: "Kafka Sink",
    icon: <Server className="w-5 h-5" />,
  },
  {
    id: "datalake-sink",
    category: "sink",
    type: "datalake",
    name: "Data Lake",
    icon: <HardDrive className="w-5 h-5" />,
  },
  {
    id: "dunkware-cloud",
    category: "sink",
    type: "dunkware-cloud",
    name: "Dunkware Cloud",
    icon: <Cloud className="w-5 h-5" />,
  },
];

// Main WorkflowEditor Component
const WorkflowEditor = () => {
  // State
  const [nodes, setNodes] = useState<Node[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editedPlaceholder, setEditedPlaceholder] = useState("");
  const [draggingConnection, setDraggingConnection] =
    useState<DraggingConnection | null>(null);
  const [ports, setPorts] = useState<ConnectionPort[]>([]);
  const [hoveredPort, setHoveredPort] = useState<string | null>(null);
  const [validTargets, setValidTargets] = useState<string[]>([]);
  // Helper Functions
  // Update these parts in the WorkflowEditor component

  // Move canConnect to useCallback
  const canConnect = useCallback(
    (sourceCategory: NodeCategory, targetCategory: NodeCategory): boolean => {
      return CONNECTION_RULES[targetCategory].includes(sourceCategory);
    },
    []
  ); // Empty deps array since CONNECTION_RULES is static

  // Update the useEffect that uses canConnect
  useEffect(() => {
    if (draggingConnection) {
      const sourcePort = ports.find(
        (p) => p.id === draggingConnection.sourcePortId
      );
      if (sourcePort) {
        const validNodeIds = nodes
          .filter((node) => canConnect(sourcePort.category, node.category))
          .map((node) => node.id);
        setValidTargets(validNodeIds);
      }
    } else {
      setValidTargets([]);
    }
  }, [draggingConnection, nodes, ports, canConnect]); // canConnect is now stable
  // Refs
  const editorRef = useRef<HTMLDivElement>(null);
  const draggedNodeRef = useRef<{
    id: string;
    offsetX: number;
    offsetY: number;
  } | null>(null);

  // Effects and Callbacks
  useEffect(() => {
    const newPorts: ConnectionPort[] = [];
    nodes.forEach((node) => {
      if (node.category !== "source") {
        newPorts.push({
          id: `input-${node.id}`,
          nodeId: node.id,
          type: "input",
          x: node.x,
          y: node.y + 20,
          category: node.category,
        });
      }
      if (node.category !== "sink") {
        newPorts.push({
          id: `output-${node.id}`,
          nodeId: node.id,
          type: "output",
          x: node.x + 120,
          y: node.y + 20,
          category: node.category,
        });
      }
    });
    setPorts(newPorts);
  }, [nodes]);

  const updateConnections = useCallback(() => {
    setConnections((prevConnections) =>
      prevConnections.map((conn) => {
        const sourceNode = nodes.find((n) => n.id === conn.sourceId);
        const targetNode = nodes.find((n) => n.id === conn.targetId);
        if (!sourceNode || !targetNode) return conn;

        return {
          ...conn,
          sourceX: sourceNode.x + 120,
          sourceY: sourceNode.y + 20,
          targetX: targetNode.x,
          targetY: targetNode.y + 20,
        };
      })
    );
  }, [nodes]);

  useEffect(() => {
    updateConnections();
  }, [nodes, updateConnections]);

  useEffect(() => {
    if (draggingConnection) {
      const sourcePort = ports.find(
        (p) => p.id === draggingConnection.sourcePortId
      );
      if (sourcePort) {
        const validNodeIds = nodes
          .filter((node) => canConnect(sourcePort.category, node.category))
          .map((node) => node.id);
        setValidTargets(validNodeIds);
      }
    } else {
      setValidTargets([]);
    }
  }, [draggingConnection, nodes, ports, canConnect]);

  const getNodeStyle = (category: NodeCategory) => {
    const baseStyles = "border-2 transition-colors";
    switch (category) {
      case "source":
        return `${baseStyles} bg-background border-primary text-foreground dark:bg-card`;
      case "decoder":
        return `${baseStyles} bg-background border-secondary text-foreground dark:bg-card`;
      case "processor":
        return `${baseStyles} bg-background border-accent text-foreground dark:bg-card`;
      case "sink":
        return `${baseStyles} bg-background border-muted text-foreground dark:bg-card`;
      default:
        return `${baseStyles} bg-background border-border text-foreground dark:bg-card`;
    }
  };
  // Add this render function inside WorkflowEditor component
  const renderPaletteSection = (category: NodeCategory, title: string) => {
    const categoryNodes = nodeTypes.filter((t) => t.category === category);
    return (
      <div className="mb-6">
        <h3 className="text-sm font-semibold mb-2 text-foreground">{title}</h3>
        <div className="space-y-2">
          {categoryNodes.map((nodeType) => (
            <div
              key={nodeType.id}
              draggable
              onDragStart={(e) => handleDragStart(e, nodeType)}
              className="flex items-center p-2 bg-card hover:bg-muted rounded-lg 
                shadow-sm cursor-move border border-border
                dark:bg-card dark:hover:bg-muted 
                dark:border-border/40 transition-colors"
            >
              <div className="text-foreground">{nodeType.icon}</div>
              <span className="ml-2 text-sm text-foreground dark:text-foreground/90">
                {nodeType.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  // Event Handlers
  const handleDragStart = (e: React.DragEvent, nodeType: NodeType) => {
    e.dataTransfer.setData("nodeType", JSON.stringify(nodeType));
  };

  const handleNodeDragStart = (e: React.MouseEvent, node: Node) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    draggedNodeRef.current = {
      id: node.id,
      offsetX: e.clientX - rect.left,
      offsetY: e.clientY - rect.top,
    };
  };

  const handleNodeDrag = (e: React.MouseEvent) => {
    if (draggedNodeRef.current && editorRef.current) {
      const { id, offsetX, offsetY } = draggedNodeRef.current;
      const rect = editorRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left - offsetX;
      const y = e.clientY - rect.top - offsetY;

      setNodes(
        nodes.map((node) => (node.id === id ? { ...node, x, y } : node))
      );

      updateConnections();
    }
  };

  const handleNodeDragEnd = () => {
    draggedNodeRef.current = null;
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const nodeType = JSON.parse(e.dataTransfer.getData("nodeType"));

    if (editorRef.current) {
      const rect = editorRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const newNode: Node = {
        id: `${nodeType.type}-${Date.now()}`,
        type: nodeType.type,
        category: nodeType.category,
        name: nodeType.name,
        x,
        y,
        placeholder: "",
      };

      setNodes([...nodes, newNode]);
    }
  };

  const handleDeleteNode = (nodeId: string) => {
    setNodes(nodes.filter((node) => node.id !== nodeId));
    setConnections(
      connections.filter(
        (conn) => conn.sourceId !== nodeId && conn.targetId !== nodeId
      )
    );
  };

  const handleEditNode = (node: Node) => {
    setSelectedNode(node);
    setEditedPlaceholder(node.placeholder || "");
    setIsEditDialogOpen(true);
  };

  const handleSaveEdit = () => {
    if (selectedNode) {
      setNodes(
        nodes.map((node) =>
          node.id === selectedNode.id
            ? { ...node, placeholder: editedPlaceholder }
            : node
        )
      );
      setIsEditDialogOpen(false);
      setSelectedNode(null);
    }
  };

  const handlePortMouseDown = (e: ReactMouseEvent, port: ConnectionPort) => {
    if (port.type === "output") {
      e.stopPropagation();
      setDraggingConnection({
        sourcePortId: port.id,
        sourceCategory: port.category,
        x1: port.x,
        y1: port.y,
        x2: port.x,
        y2: port.y,
      });
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (draggedNodeRef.current) {
      handleNodeDrag(e);
    }
    if (draggingConnection && editorRef.current) {
      const rect = editorRef.current.getBoundingClientRect();
      setDraggingConnection((prev) => ({
        ...prev!,
        x2: e.clientX - rect.left,
        y2: e.clientY - rect.top,
      }));
    }
  };

  const handlePortMouseUp = (port: ConnectionPort) => {
    if (draggingConnection && port.type === "input") {
      const sourcePort = ports.find(
        (p) => p.id === draggingConnection.sourcePortId
      );
      if (
        sourcePort &&
        sourcePort.nodeId !== port.nodeId &&
        canConnect(sourcePort.category, port.category)
      ) {
        const connectionExists = connections.some(
          (conn) =>
            conn.sourceId === sourcePort.nodeId && conn.targetId === port.nodeId
        );

        if (!connectionExists) {
          setConnections([
            ...connections,
            {
              id: `conn-${Date.now()}`,
              sourceId: sourcePort.nodeId,
              targetId: port.nodeId,
              sourceX: sourcePort.x,
              sourceY: sourcePort.y,
              targetX: port.x,
              targetY: port.y,
            },
          ]);
        }
      }
    }
    setDraggingConnection(null);
  };

  // Render Functions
  const renderConnections = () => (
    <svg className="absolute inset-0 pointer-events-none">
      {connections.map((conn) => (
        <g key={conn.id}>
          <path
            d={`M ${conn.sourceX} ${conn.sourceY} C ${conn.sourceX + 50} ${
              conn.sourceY
            }, ${conn.targetX - 50} ${conn.targetY}, ${conn.targetX} ${
              conn.targetY
            }`}
            className="stroke-foreground"
            strokeWidth="2"
            fill="none"
            markerEnd="url(#arrowhead)"
          />
        </g>
      ))}

      {draggingConnection && (
        <path
          d={`M ${draggingConnection.x1} ${draggingConnection.y1} C ${
            draggingConnection.x1 + 50
          } ${draggingConnection.y1}, ${draggingConnection.x2 - 50} ${
            draggingConnection.y2
          }, ${draggingConnection.x2} ${draggingConnection.y2}`}
          stroke="#666"
          strokeWidth="2"
          fill="none"
          strokeDasharray="5,5"
          markerEnd="url(#arrowhead)"
        />
      )}

      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="9"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="#666" />
        </marker>
      </defs>
    </svg>
  );

  const renderPorts = (node: Node) => {
    const showPort = (type: "input" | "output") => {
      if (type === "input" && node.category === "source") return false;
      if (type === "output" && node.category === "sink") return false;
      return true;
    };

    return (
      <>
        {showPort("input") && (
          <div
            className={`absolute w-3 h-3 rounded-full border-2 -left-1.5 top-1/2 transform -translate-y-1/2 cursor-pointer
            ${
              hoveredPort === `input-${node.id}`
                ? "bg-primary"
                : "bg-background dark:bg-card"
            }
              ${
                validTargets.includes(node.id)
                  ? "border-green-500"
                  : "border-gray-400"
              }`}
            onMouseDown={(e) =>
              handlePortMouseDown(e, {
                id: `input-${node.id}`,
                nodeId: node.id,
                type: "input",
                x: node.x,
                y: node.y + 20,
                category: node.category,
              })
            }
            onMouseUp={() =>
              handlePortMouseUp({
                id: `input-${node.id}`,
                nodeId: node.id,
                type: "input",
                x: node.x,
                y: node.y + 20,
                category: node.category,
              })
            }
            onMouseEnter={() => setHoveredPort(`input-${node.id}`)}
            onMouseLeave={() => setHoveredPort(null)}
          />
        )}
        {showPort("output") && (
          <div
            className={`absolute w-3 h-3 rounded-full border-2 -right-1.5 top-1/2 transform -translate-y-1/2 cursor-pointer
              ${
                hoveredPort === `output-${node.id}` ? "bg-blue-400" : "bg-white"
              }
              border-gray-400`}
            onMouseDown={(e) =>
              handlePortMouseDown(e, {
                id: `output-${node.id}`,
                nodeId: node.id,
                type: "output",
                x: node.x + 120,
                y: node.y + 20,
                category: node.category,
              })
            }
            onMouseEnter={() => setHoveredPort(`output-${node.id}`)}
            onMouseLeave={() => setHoveredPort(null)}
          />
        )}
      </>
    );
  };

  const renderEditDialog = () => {
    const footerContent = (
      <>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
          onClick={handleSaveEdit}
        >
          Save
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => setIsEditDialogOpen(false)}
        >
          Cancel
        </button>
      </>
    );

    return (
      <Dialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        title="Edit Node"
        footer={footerContent}
      >
        <div className="mt-2">
          <input
            type="text"
            value={editedPlaceholder}
            onChange={(e) => setEditedPlaceholder(e.target.value)}
            placeholder="Enter placeholder text"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-3"
          />
        </div>
      </Dialog>
    );
  };

  // Main Render
  return (
    <div className="flex h-screen bg-background text-foreground">
      {/* Palette */}
      <div className="w-64 bg-card p-4 border-r border-border overflow-y-auto">
        {renderPaletteSection("source", "Data Sources")}
        {renderPaletteSection("decoder", "Decoders")}
        {renderPaletteSection("processor", "Processors")}
        {renderPaletteSection("sink", "Data Sinks")}
      </div>

      {/* Canvas */}
      <div
        ref={editorRef}
        className="flex-1 relative bg-background"
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
        onMouseMove={handleMouseMove}
        onMouseUp={() => {
          handleNodeDragEnd();
          setDraggingConnection(null);
        }}
      >
        {renderConnections()}

        {nodes.map((node) => (
          <ContextMenu
            key={node.id}
            items={[
              {
                label: "Edit",
                onClick: () => handleEditNode(node),
                icon: <Pencil className="w-4 h-4" />,
              },
              {
                label: "Delete",
                onClick: () => handleDeleteNode(node.id),
                icon: <Trash2 className="w-4 h-4" />,
                danger: true,
              },
            ]}
          >
            <div
              className={`absolute p-3 rounded-lg border-2 cursor-move ${getNodeStyle(
                node.category
              )}`}
              style={{ left: node.x, top: node.y, width: "120px" }}
              onMouseDown={(e) => handleNodeDragStart(e, node)}
            >
              <div className="flex items-center space-x-2">
                {nodeTypes.find((t) => t.type === node.type)?.icon}
                <span className="font-medium text-sm">{node.name}</span>
              </div>
              {node.placeholder && (
                <div className="mt-1 text-xs text-muted-foreground">
                  {node.placeholder}
                </div>
              )}
              {renderPorts(node)}
            </div>
          </ContextMenu>
        ))}

        {renderEditDialog()}
      </div>
    </div>
  );
};

export default WorkflowEditor;
