import React from "react";
import { useTheme } from "@/providers/ThemeProvider";
import { Button } from "@/components/ui/button";
import { MoonIcon, SunIcon } from "lucide-react";

interface MinimalLayoutProps {
  children: React.ReactNode;
}

export const MinimalLayout: React.FC<MinimalLayoutProps> = ({ children }) => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="min-h-screen bg-background">
      {/* Minimal header with just theme toggle */}
      <div className="absolute top-4 right-4">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setTheme(theme === "light" ? "dark" : "light")}
          className="rounded-full"
        >
          {theme === "light" ? (
            <MoonIcon className="h-4 w-4" />
          ) : (
            <SunIcon className="h-4 w-4" />
          )}
        </Button>
      </div>

      {/* Main content */}
      <main className="flex-1">{children}</main>
    </div>
  );
};

export default MinimalLayout;
