// src/modules/concepts/canvas/ConceptCanvas.tsx
import React from 'react';
import { ConceptCanvasConfig } from './types';

import { ConceptCanvasRoutes } from './routes';
import { ConceptCanvasProvider } from './context/ConceptCanvasContext';
interface ConceptCanvasProps {
  config?: ConceptCanvasConfig;
  className?: string;
}

export const ConceptCanvas: React.FC<ConceptCanvasProps> = ({ 
  config,
  className = ''
}) => {
  return (
    <ConceptCanvasProvider initialConfig={config}>
      <div className={className}>
        <ConceptCanvasRoutes />
      </div>
    </ConceptCanvasProvider>
  );
};

// src/modules/concepts/canvas/index.ts
