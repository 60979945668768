import React from 'react';

interface QuickActionCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export const QuickActionCard: React.FC<QuickActionCardProps> = ({
  title,
  description,
  icon,
  onClick
}) => (
  <button
    onClick={onClick}
    className="p-6 bg-white rounded-xl border shadow-sm hover:shadow-md transition-shadow
      flex items-start space-x-4 text-left w-full"
  >
    <div className="p-2 bg-blue-50 rounded-lg text-blue-600">
      {icon}
    </div>
    <div>
      <h3 className="font-medium text-gray-900">{title}</h3>
      <p className="text-sm text-gray-500">{description}</p>
    </div>
  </button>
);