import { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, ArrowLeft } from "lucide-react";
import { Card } from "@/components/ui/card";

interface Section {
  title: string;
  content: string;
  features?: string[];
  benefits?: string[];
  diagram?: boolean;
}

const DetailedContent = ({
  section,
  onBack,
}: {
  section: Section;
  onBack: () => void;
}) => {
  return (
    <div className="animate-fadeIn">
      <button
        onClick={onBack}
        className="mb-6 flex items-center text-blue-500 hover:text-blue-600 transition-colors"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back to Cards
      </button>

      <div className="space-y-8">
        <div className="space-y-4">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            {section.title}
          </h2>
          <div className="inline-flex px-3 py-1 rounded-full bg-blue-50 dark:bg-blue-900">
            <p className="text-sm font-medium text-blue-600 dark:text-blue-300">
              Core Principle
            </p>
          </div>
        </div>

        <div className="prose dark:prose-invert max-w-none">
          <p className="text-lg text-gray-600 dark:text-gray-300">
            {section.content}
          </p>

          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card className="p-6 bg-gray-50 dark:bg-gray-800">
              <h3 className="text-xl font-semibold mb-4">Key Features</h3>
              <ul className="space-y-3">
                {section.features?.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <span className="w-2 h-2 mt-2 mr-2 bg-blue-500 rounded-full" />
                    <span className="text-gray-600 dark:text-gray-300">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </Card>

            <Card className="p-6 bg-gray-50 dark:bg-gray-800">
              <h3 className="text-xl font-semibold mb-4">Benefits</h3>
              <ul className="space-y-3">
                {section.benefits?.map((benefit, index) => (
                  <li key={index} className="flex items-start">
                    <span className="w-2 h-2 mt-2 mr-2 bg-green-500 rounded-full" />
                    <span className="text-gray-600 dark:text-gray-300">
                      {benefit}
                    </span>
                  </li>
                ))}
              </ul>
            </Card>
          </div>

          {section.diagram && (
            <div className="mt-8">
              <Card className="p-6 bg-gray-50 dark:bg-gray-800">
                <h3 className="text-xl font-semibold mb-4">
                  Architecture Overview
                </h3>
                <div className="aspect-video bg-gray-200 dark:bg-gray-700 rounded-lg flex items-center justify-center">
                  <span className="text-gray-500 dark:text-gray-400">
                    Diagram Placeholder
                  </span>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface ManifestCardProps {
  title: string;
  content: string;
  isActive: boolean;
  isCentered: boolean;
  onClick: () => void;
}

const ManifestCard = ({
  title,
  content,
  isCentered,
  onClick,
}: ManifestCardProps) => (
  <div
    onClick={onClick}
    className={`w-full md:w-1/3 flex-shrink-0 px-3 transition-all duration-500 cursor-pointer 
    ${isCentered ? "scale-110 z-10" : "scale-90 opacity-75"}`}
  >
    <Card className="h-full hover:shadow-lg transition-shadow">
      <div className="p-6 flex flex-col h-full bg-white dark:bg-gray-800 rounded-lg">
        <div className="space-y-4 flex-grow">
          <div className="space-y-2">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {title}
            </h3>
            <div className="inline-flex px-2 py-1 rounded-full bg-blue-50 dark:bg-blue-900">
              <p className="text-xs font-medium text-blue-600 dark:text-blue-300">
                Core Principle
              </p>
            </div>
          </div>
          <p className="text-gray-600 dark:text-gray-300 text-sm leading-relaxed">
            {content}
          </p>
        </div>
        <div className="pt-4 mt-4 border-t border-gray-100 dark:border-gray-700">
          <span className="text-xs text-gray-500 dark:text-gray-400">
            Click to learn more
          </span>
        </div>
      </div>
    </Card>
  </div>
);

const Manifest = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);

  const sections = [
    {
      title: "Kubernetes-Driven Foundation",
      content:
        "Concept Cloud is built on the foundation of Kubernetes, the gold standard for scalable, containerized computing. Kubernetes empowers us to dynamically manage resources, scale with precision, and provide unparalleled flexibility.",
      features: [
        "Native Kubernetes integration",
        "Dynamic resource management",
        "Automated scaling and orchestration",
        "Container-native architecture",
      ],
      benefits: [
        "Improved resource utilization",
        "Reduced operational overhead",
        "Enhanced system reliability",
        "Seamless scalability",
      ],
      diagram: true,
    },
    {
      title: "Concept-First Approach",
      content:
        "At the heart of Concept Cloud lies the Concept: modular, user-defined bundles of logic that abstract complexity and enable new possibilities. Our approach puts concepts first, allowing users to transform ideas into reality.",
      features: [
        "Modular concept architecture",
        "User-defined logic bundles",
        "Concept marketplace",
        "Visual concept builder",
      ],
      benefits: [
        "Rapid development cycles",
        "Reduced complexity",
        "Increased reusability",
        "Enhanced collaboration",
      ],
      diagram: true,
    },
    {
      title: "Equity Feed",
      content:
        "Cloud sprawl and siloed architectures have created barriers to innovation. Concept Cloud unifies compute, data, and integrations into a single, scalable platform. Everything works in harmony—a single ecosystem tailored to your needs.",
      features: [
        "Unified control plane",
        "Cross-cloud orchestration",
        "Integrated data management",
        "Seamless service mesh",
      ],
      benefits: [
        "Simplified management",
        "Reduced costs",
        "Improved security",
        "Enhanced visibility",
      ],
      diagram: true,
    },
    {
      title: "Simple Moving Averages",
      content:
        "Scaling the cloud isn't just about adding more resources; it's about scaling intelligently. Concept Cloud introduces a logic-driven approach to scalability, optimizing for cost, performance, and efficiency.",
      features: [
        "Intelligent autoscaling",
        "Predictive resource allocation",
        "Cost optimization",
        "Performance analytics",
      ],
      benefits: [
        "Optimized resource usage",
        "Reduced cloud costs",
        "Improved performance",
        "Better user experience",
      ],
      diagram: true,
    },
    {
      title: "Moving Trade Count",
      content:
        "Concept Cloud is more than a platform; it's a canvas. Users can create, extend, and compose concepts, leveraging an intuitive interface and powerful tools. By building from existing concepts, users unlock exponential possibilities.",
      features: [
        "Visual development tools",
        "Concept composition",
        "Template library",
        "Custom extensions",
      ],
      benefits: [
        "Faster development",
        "Lower barrier to entry",
        "Increased innovation",
        "Enhanced productivity",
      ],
      diagram: true,
    },
  ];

  const handlePrevious = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => (prev === 0 ? sections.length - 3 : prev - 1));
      setTimeout(() => setIsAnimating(false), 500);
    }
  };

  const handleNext = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => (prev === sections.length - 3 ? 0 : prev + 1));
      setTimeout(() => setIsAnimating(false), 500);
    }
  };

  useEffect(() => {
    if (!selectedSection) {
      const timer = setInterval(() => {
        handleNext();
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [currentIndex, selectedSection, handleNext]);

  const handleCardClick = (section: Section) => {
    setSelectedSection(section);
  };

  return (
    <div className="max-w-7xl mx-auto p-8">
      <div className="text-center space-y-4 mb-20">
        <div className="flex items-center justify-center text-2xl font-bold">
          <span className="text-3xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
            DUNKWARE
          </span>
        </div>
        <p className="text-lg text-gray-600 dark:text-gray-300">Let's Dunk</p>
      </div>

      <Card className="bg-gray-50 dark:bg-gray-900 border-0 shadow-lg relative">
        <div className="relative p-6">
          {!selectedSection ? (
            <>
              <button
                onClick={handlePrevious}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-6 z-20 
                         bg-white dark:bg-gray-800 rounded-full p-2 shadow-lg 
                         hover:bg-gray-50 dark:hover:bg-gray-700 
                         transition-all duration-200 transform hover:scale-110
                         focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isAnimating}
              >
                <ChevronLeft className="w-6 h-6 text-gray-600 dark:text-gray-300" />
              </button>

              <button
                onClick={handleNext}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-6 z-20 
                         bg-white dark:bg-gray-800 rounded-full p-2 shadow-lg 
                         hover:bg-gray-50 dark:hover:bg-gray-700 
                         transition-all duration-200 transform hover:scale-110
                         focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isAnimating}
              >
                <ChevronRight className="w-6 h-6 text-gray-600 dark:text-gray-300" />
              </button>

              <div className="overflow-hidden">
                <div
                  className="flex transition-transform duration-500 ease-in-out"
                  style={{
                    transform: `translateX(-${currentIndex * (100 / 3)}%)`,
                  }}
                >
                  {sections.map((section, index) => (
                    <ManifestCard
                      key={index}
                      {...section}
                      isActive={
                        index >= currentIndex && index < currentIndex + 3
                      }
                      isCentered={index === currentIndex + 1}
                      onClick={() => handleCardClick(section)}
                    />
                  ))}
                </div>
              </div>

              <div className="flex justify-center mt-8 gap-2">
                {Array.from({ length: sections.length - 2 }).map((_, index) => (
                  <button
                    key={index}
                    className={`w-2 h-2 rounded-full transition-all duration-500 ${
                      currentIndex === index
                        ? "w-8 bg-blue-500"
                        : "bg-gray-300 dark:bg-gray-600 hover:bg-gray-400"
                    }`}
                    onClick={() => {
                      if (!isAnimating) {
                        setIsAnimating(true);
                        setCurrentIndex(index);
                        setTimeout(() => setIsAnimating(false), 500);
                      }
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <DetailedContent
              section={selectedSection}
              onBack={() => setSelectedSection(null)}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Manifest;
