import React, { useState, useEffect } from "react";
import { Activity, Box, Clock, Database, Key } from "lucide-react";

import { clsx } from "clsx";

// Types
interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface AnalyzerStats {
  messagesAnalyzed: number;
  messagesPerSecond: number;
  lastUpdate: number;
}

interface ArrayStats {
  minLength: number;
  maxLength: number;
  averageLength: number;
}

interface StructureAnalysis {
  isAlwaysArray: boolean;
  arrayStats: ArrayStats | null;
}

interface FieldAnalysis {
  occurrenceRate: number;
  types: string[];
  uniqueValues: number;
}

interface AnalyzerReport {
  structureAnalysis: StructureAnalysis;
  fieldAnalysis: Record<string, FieldAnalysis>;
  possibleIdFields: string[];
}

type BadgeVariant = "default" | "secondary" | "outline";

interface BadgeProps {
  variant?: BadgeVariant;
  children: React.ReactNode;
}

// Components
const Card: React.FC<CardProps> = ({ className, children, ...props }) => (
  <div
    className={clsx(
      "rounded-lg border bg-card text-card-foreground shadow",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

const CardHeader: React.FC<CardProps> = ({ className, children, ...props }) => (
  <div className={clsx("flex flex-col space-y-1.5 p-6", className)} {...props}>
    {children}
  </div>
);

interface CardTitleProps {
  className?: string;
  children: React.ReactNode;
}

const CardTitle: React.FC<CardTitleProps> = ({ className, children }) => (
  <h3 className={clsx("font-semibold leading-none tracking-tight", className)}>
    {children}
  </h3>
);

const CardContent: React.FC<CardProps> = ({
  className,
  children,
  ...props
}) => (
  <div className={clsx("p-6 pt-0", className)} {...props}>
    {children}
  </div>
);

const Badge: React.FC<BadgeProps> = ({ variant = "default", children }) => (
  <span
    className={clsx(
      "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
      {
        "bg-primary/10 text-primary ring-primary/20": variant === "default",
        "bg-secondary/10 text-secondary ring-secondary/20":
          variant === "secondary",
        "bg-muted/10 text-muted ring-muted/20": variant === "outline",
      }
    )}
  >
    {children}
  </span>
);

const JSONAnalyzerDashboard: React.FC = () => {
  const [startTime] = useState<number>(Date.now());
  const [stats, setStats] = useState<AnalyzerStats>({
    messagesAnalyzed: 0,
    messagesPerSecond: 0,
    lastUpdate: Date.now(),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setStats((prev) => {
        const now = Date.now();
        const runningTime = (now - startTime) / 1000;
        return {
          messagesAnalyzed:
            prev.messagesAnalyzed + Math.floor(Math.random() * 10),
          messagesPerSecond: Number(
            (
              (prev.messagesAnalyzed + Math.floor(Math.random() * 10)) /
              runningTime
            ).toFixed(2)
          ),
          lastUpdate: now,
        };
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime]);

  // Sample analysis report
  const report: AnalyzerReport = {
    structureAnalysis: {
      isAlwaysArray: false,
      arrayStats: {
        minLength: 1,
        maxLength: 5,
        averageLength: 2.5,
      },
    },
    fieldAnalysis: {
      id: {
        occurrenceRate: 1,
        types: ["string"],
        uniqueValues: 150,
      },
      type: {
        occurrenceRate: 0.95,
        types: ["string"],
        uniqueValues: 3,
      },
      "data.name": {
        occurrenceRate: 0.98,
        types: ["string"],
        uniqueValues: 145,
      },
      "data.age": {
        occurrenceRate: 0.85,
        types: ["number"],
        uniqueValues: 50,
      },
    },
    possibleIdFields: ["id", "data.name"],
  };

  return (
    <div className="p-6 space-y-6 max-w-6xl mx-auto">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Messages Analyzed
            </CardTitle>
            <Database className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {stats.messagesAnalyzed.toLocaleString()}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Messages/Second
            </CardTitle>
            <Activity className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.messagesPerSecond}</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Running Time</CardTitle>
            <Clock className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {((Date.now() - startTime) / 1000).toFixed(1)}s
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Structure Analysis */}
      <Card>
        <CardHeader>
          <CardTitle className="text-xl flex items-center gap-2">
            <Box className="h-5 w-5" />
            Structure Analysis
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div>
              <span className="font-medium">Message Format: </span>
              <Badge
                variant={
                  report.structureAnalysis.isAlwaysArray
                    ? "default"
                    : "secondary"
                }
              >
                {report.structureAnalysis.isAlwaysArray
                  ? "Always Array"
                  : "Mixed/Object"}
              </Badge>
            </div>
            {report.structureAnalysis.arrayStats && (
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="text-sm text-muted-foreground">
                    Min Length
                  </div>
                  <div className="text-lg font-medium">
                    {report.structureAnalysis.arrayStats.minLength}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground">
                    Max Length
                  </div>
                  <div className="text-lg font-medium">
                    {report.structureAnalysis.arrayStats.maxLength}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground">
                    Average Length
                  </div>
                  <div className="text-lg font-medium">
                    {report.structureAnalysis.arrayStats.averageLength}
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Field Analysis */}
      <Card>
        <CardHeader>
          <CardTitle className="text-xl flex items-center gap-2">
            <Key className="h-5 w-5" />
            Field Analysis
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs uppercase bg-muted/50">
                <tr>
                  <th className="px-6 py-3">Field Path</th>
                  <th className="px-6 py-3">Occurrence Rate</th>
                  <th className="px-6 py-3">Types</th>
                  <th className="px-6 py-3">Unique Values</th>
                  <th className="px-6 py-3">Possible ID</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(report.fieldAnalysis).map(
                  ([field, analysis]) => (
                    <tr key={field} className="border-b">
                      <td className="px-6 py-4 font-medium">{field}</td>
                      <td className="px-6 py-4">
                        {(analysis.occurrenceRate * 100).toFixed(1)}%
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex gap-1">
                          {analysis.types.map((type) => (
                            <Badge key={type} variant="outline">
                              {type}
                            </Badge>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-4">{analysis.uniqueValues}</td>
                      <td className="px-6 py-4">
                        {report.possibleIdFields.includes(field) && (
                          <Badge variant="secondary">ID Field</Badge>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default JSONAnalyzerDashboard;
