import { Brain, Workflow } from "lucide-react";
import { ConceptTypeCard } from "../components";

export const CreateConceptPage: React.FC = () => {
    return (
      <div className="p-6 max-w-7xl mx-auto">
        <div className="space-y-6">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Create New Concept</h2>
            <p className="text-gray-500">Choose a concept type to get started</p>
          </div>
  
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <ConceptTypeCard
              title="Integration Concept"
              description="Connect and transform data streams"
              icon={<Workflow className="w-6 h-6" />}
            />
            <ConceptTypeCard
              title="Analysis Concept"
              description="Process and analyze data patterns"
              icon={<Brain className="w-6 h-6" />}
            />
            {/* Add more concept types */}
          </div>
        </div>
      </div>
    );
  };
  