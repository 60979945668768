import React, { useState, ReactNode } from 'react';

interface TabsProps {
  children: ReactNode;
  defaultValue: string;
}

interface TabsListProps {
  children: ReactNode;
  className?: string;
}

interface TabsTriggerProps {
  children: ReactNode;
  value: string;
  active?: boolean;
  onClick?: () => void;
}

interface TabsContentProps {
  children: ReactNode;
  value: string;
}

const Tabs: React.FC<TabsProps> = ({ children, defaultValue }) => {
  const [activeTab, setActiveTab] = useState(defaultValue);
  
  return (
    <div className="w-full">
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          if (child.type === TabsList) {
            // Pass active tab state to TabsList
            return React.cloneElement(child, {
              ...child.props,
              activeTab,
              setActiveTab,
            });
          }
          if (child.type === TabsContent) {
            // Only render content for active tab
            return child.props.value === activeTab ? child : null;
          }
          return child;
        }
        return null;
      })}
    </div>
  );
};

const TabsList = React.forwardRef<HTMLDivElement, TabsListProps>(
  ({ children, className = "" }, ref) => {
    return (
      <div 
        ref={ref} 
        className={`flex space-x-1 border-b ${className}`}
      >
        {children}
      </div>
    );
  }
);

TabsList.displayName = 'TabsList';

const TabsTrigger = React.forwardRef<HTMLButtonElement, TabsTriggerProps>(
    ({ children,  active, onClick }, ref) => {
      return (
        <button
          ref={ref}
          onClick={onClick}
          className={`px-4 py-2 text-sm font-medium transition-colors
            ${active 
              ? 'text-primary border-b-2 border-primary' 
              : 'text-muted-foreground hover:text-primary'
            }`}
        >
          {children}
        </button>
      );
    }
  );

TabsTrigger.displayName = 'TabsTrigger';

const TabsContent = React.forwardRef<HTMLDivElement, TabsContentProps>(
  ({ children }, ref) => {
    return (
      <div ref={ref} className="mt-4">
        {children}
      </div>
    );
  }
);

TabsContent.displayName = 'TabsContent';

export { Tabs, TabsList, TabsTrigger, TabsContent };