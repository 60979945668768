import React, { useState } from "react";
import {
  Database,
  ChevronRight,
  ChevronDown,
  Server,
  Home,
  List,
  Grid,
  Settings,
  Activity,
  AlertCircle,
} from "lucide-react";

// Type definitions
interface InstanceView {
  id: string;
  title: string;
  icon: React.ReactNode;
}

interface NavigationItem {
  id: string;
  title: string;
  path?: string;
  icon?: React.ReactNode;
  children?: NavigationItem[];
  instanceViews?: InstanceView[];
}

interface CloudAssets {
  cloudAssets: NavigationItem;
}

interface Cluster {
  id: number;
  name: string;
  status: "healthy" | "warning" | "error";
}

interface BreadcrumbProps {
  path: string;
}

interface TabNavigationProps {
  tabs: InstanceView[];
  activeTab: string;
  onTabChange: (tabId: string) => void;
}

interface MenuItemProps {
  item: NavigationItem;
  depth?: number;
  activePath: string;
  onNavigate: (path: string) => void;
}

// Navigation data structure
const navigationData: CloudAssets = {
  cloudAssets: {
    id: "cloud-assets",
    title: "Data Discovery",
    icon: <Database className="w-5 h-5" />,
    children: [
      {
        id: "kafka",
        title: "Agents",
        path: "/cloud-assets/kafka",
        icon: <Server className="w-4 h-4" />,
        instanceViews: [
          {
            id: "overview",
            title: "Overview",
            icon: <Home className="w-4 h-4" />,
          },
          { id: "topics", title: "Topics", icon: <List className="w-4 h-4" /> },
          {
            id: "monitoring",
            title: "Monitoring",
            icon: <Activity className="w-4 h-4" />,
          },
          {
            id: "settings",
            title: "Settings",
            icon: <Settings className="w-4 h-4" />,
          },
          {
            id: "alerts",
            title: "Alerts",
            icon: <AlertCircle className="w-4 h-4" />,
          },
        ],
      },
      {
        id: "cassandra",
        title: "Cassandra Clusters",
        path: "/cloud-assets/cassandra",
        icon: <Server className="w-4 h-4" />,
        instanceViews: [
          {
            id: "overview",
            title: "Overview",
            icon: <Home className="w-4 h-4" />,
          },
          {
            id: "keyspaces",
            title: "Keyspaces",
            icon: <List className="w-4 h-4" />,
          },
          {
            id: "monitoring",
            title: "Monitoring",
            icon: <Activity className="w-4 h-4" />,
          },
          {
            id: "settings",
            title: "Settings",
            icon: <Settings className="w-4 h-4" />,
          },
        ],
      },
    ],
  },
};

const getActiveAssetType = (path: string): NavigationItem | undefined => {
  const assetType = navigationData.cloudAssets.children?.find((child) =>
    path.startsWith(`/cloud-assets/${child.id}`)
  );
  return assetType;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ path }) => {
  const pathSegments = path.split("/").filter(Boolean);

  return (
    <div className="flex items-center space-x-2 px-6 py-3 bg-gray-50 border-b">
      {pathSegments.map((segment: string, index: number) => (
        <React.Fragment key={segment}>
          {index > 0 && <ChevronRight className="w-4 h-4 text-gray-400" />}
          <span className="capitalize text-gray-600">
            {segment.replace("-", " ")}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
};

const TabNavigation: React.FC<TabNavigationProps> = ({
  tabs,
  activeTab,
  onTabChange,
}) => {
  return (
    <div className="border-b border-gray-200">
      <nav className="flex space-x-8 px-6" aria-label="Tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
            className={`
              flex items-center space-x-2 py-4 px-1 border-b-2 font-medium text-sm
              ${
                activeTab === tab.id
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }
            `}
          >
            {tab.icon}
            <span>{tab.title}</span>
          </button>
        ))}
      </nav>
    </div>
  );
};

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  depth = 0,
  activePath,
  onNavigate,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const hasChildren = item.children && item.children.length > 0;
  const isActive = activePath.startsWith(item.path || "");

  return (
    <div className="w-full">
      <div
        className={`
          flex items-center px-4 py-2 cursor-pointer
          ${isActive ? "bg-blue-50 text-blue-600" : "hover:bg-gray-50"}
          ${depth === 0 ? "font-medium" : "font-normal"}
        `}
        style={{ paddingLeft: `${depth * 1}rem` }}
        onClick={() => {
          if (hasChildren) {
            setIsOpen(!isOpen);
          } else if (item.path) {
            onNavigate(item.path);
          }
        }}
      >
        {item.icon && <span className="mr-2">{item.icon}</span>}
        <span className="flex-grow">{item.title}</span>
        {hasChildren && (
          <span className="ml-2">
            {isOpen ? (
              <ChevronDown className="w-4 h-4" />
            ) : (
              <ChevronRight className="w-4 h-4" />
            )}
          </span>
        )}
      </div>

      {hasChildren && isOpen && item.children && (
        <div className="ml-4">
          {item.children.map((child) => (
            <MenuItem
              key={child.id}
              item={child}
              depth={depth + 1}
              activePath={activePath}
              onNavigate={onNavigate}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const CloudConsole: React.FC = () => {
  const [activePath, setActivePath] = useState("/cloud-assets/kafka");
  const [activeTab, setActiveTab] = useState("overview");
  const [viewType, setViewType] = useState<"grid" | "list">("grid");

  const clusters: Cluster[] = [
    { id: 1, name: "prod-kafka-01", status: "healthy" },
    { id: 2, name: "stage-kafka-01", status: "warning" },
  ];

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Left Navigation */}
      <nav className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
        <div className="p-4 border-b border-gray-200">
          <h1 className="text-xl font-bold">Integration </h1>
        </div>
        <div className="py-4">
          <MenuItem
            item={navigationData.cloudAssets}
            activePath={activePath}
            onNavigate={setActivePath}
          />
        </div>
      </nav>

      {/* Main Content Area */}
      <div className="flex-1 overflow-hidden flex flex-col">
        {/* Breadcrumb */}
        <Breadcrumb path={activePath} />

        {/* Content Header */}
        <div className="px-6 py-4 flex justify-between items-center border-b bg-white">
          <h2 className="text-2xl font-bold">Integration Concepts</h2>
          <div className="flex items-center space-x-4">
            {/* View toggle */}
            <div className="flex items-center space-x-2 border rounded-lg p-1">
              <button
                onClick={() => setViewType("grid")}
                className={`p-1 rounded ${
                  viewType === "grid" ? "bg-gray-100" : ""
                }`}
              >
                <Grid className="w-5 h-5" />
              </button>
              <button
                onClick={() => setViewType("list")}
                className={`p-1 rounded ${
                  viewType === "list" ? "bg-gray-100" : ""
                }`}
              >
                <List className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Instance View with Tabs */}
        {activePath.includes("/instance/") && (
          <>
            {(() => {
              const activeAsset = getActiveAssetType(activePath);
              return activeAsset?.instanceViews ? (
                <TabNavigation
                  tabs={activeAsset.instanceViews}
                  activeTab={activeTab}
                  onTabChange={setActiveTab}
                />
              ) : null;
            })()}
          </>
        )}

        {/* Main Content */}
        <div className="flex-1 overflow-auto p-6">
          {/* Grid/List View of Clusters */}
          {!activePath.includes("/instance/") && (
            <div
              className={`grid ${
                viewType === "grid" ? "grid-cols-3" : "grid-cols-1"
              } gap-4`}
            >
              {clusters.map((cluster) => (
                <div
                  key={cluster.id}
                  className="bg-white p-4 rounded-lg border hover:shadow-md cursor-pointer"
                  onClick={() =>
                    setActivePath(`${activePath}/instance/${cluster.id}`)
                  }
                >
                  <h3 className="font-medium">{cluster.name}</h3>
                  <span
                    className={`
                    inline-flex items-center px-2 py-1 rounded-full text-xs
                    ${
                      cluster.status === "healthy"
                        ? "bg-green-100 text-green-800"
                        : "bg-yellow-100 text-yellow-800"
                    }
                  `}
                  >
                    {cluster.status}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CloudConsole;
