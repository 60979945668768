import axios  from "axios";
import { AuthResponse, LoginCredentials, User } from "../types/auth";

const API_BASE_URL = import.meta.env.VITE_API_URL;
const AUTH_URL = `${API_BASE_URL}/api/auth`;
const USERS_URL = `${API_BASE_URL}/api/user`;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
const login = async (credentials: LoginCredentials): Promise<AuthResponse> => {
  console.log("Attempting login with credentials:", {
    username: credentials.username,
    password: '***'
  });

  try {
    const response = await api.post<AuthResponse>(`${AUTH_URL}/login`, credentials);
    console.log("Login successful. Response:", response.data);

    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      
      // Immediately fetch and store user info
      try {
        const userInfo = await getUserInfo();
        return {
          ...response.data,
          user: userInfo
        };
      } catch (userError) {
        console.error("Failed to fetch user info after login:", userError);
        // Still return successful login but with no user
        return response.data;
      }
    }

    return response.data;
  } catch (error: any) {
    console.error("Login failed. Error details:", error);
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
    }
    throw new Error(error.response?.data?.message || "Login failed");
  }
};
const validateToken = async (): Promise<AuthResponse> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }
    
    const response = await api.post<AuthResponse>(`${AUTH_URL}/validate`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log("Token validation response:", response.data);
    return response.data;
  } catch (error: any) {
    console.error("Token validation failed:", error);
    localStorage.removeItem('token');
    throw new Error(error.response?.data?.message || "Token validation failed");
  }
};


const getUserInfo = async (): Promise<User> => {
  try {
    const response = await api.get<User>(`${USERS_URL}/me`);
    console.log("User info retrieved:", response.data);
    
    localStorage.setItem('user', JSON.stringify(response.data));
    return response.data;
  } catch (error: any) {
    console.error("Failed to fetch user info:", error);
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    throw new Error(error.response?.data?.message || "Failed to fetch user info");
  }
};

const logout = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

// In authService.ts, modify isAuthenticated:
const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('token');
  // Only check for token, not user
  return !!token;
};

const getCurrentUser = (): User | null => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

const authService = {
  login,
  validateToken,
  getUserInfo,
  logout,
  isAuthenticated,
  getCurrentUser
};

export default authService;