import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '@/services/authService';
import type { User } from '@/types/auth';

interface AuthContextType {
  user: User | null;
  error: string | null;
  loading: boolean;
  isAuthenticated: boolean;
  login: (credentials: { username: string; password: string }) => Promise<boolean>;
  logout: () => void;
  clearError: () => void;
}

// Create context with null as initial value
const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Add effect to initialize user from localStorage
  useEffect(() => {
    const initializeAuth = async () => {
      if (authService.isAuthenticated()) {
        try {
          // Try to get user from localStorage first
          const storedUser = authService.getCurrentUser();
          if (storedUser) {
            setUser(storedUser);
          } else {
            // If no stored user but we have token, fetch fresh user info
            const userInfo = await authService.getUserInfo();
            setUser(userInfo);
          }
        } catch (error) {
          console.error('Failed to initialize user:', error);
          // On any error, clear auth state
          authService.logout();
          setUser(null);
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const login = async (credentials: { username: string; password: string }): Promise<boolean> => {
    try {
      setError(null);
      console.log('Starting login process...');
      
      const response = await authService.login(credentials);
      console.log('Login response:', response);
  
      if (response.success && response.token) {
        // User info should already be fetched and stored by authService.login
        const userInfo = await authService.getUserInfo();
        setUser(userInfo);
        
        // Add a small delay to ensure state is updated
        await new Promise(resolve => setTimeout(resolve, 100));
        
        navigate('/dashboard');
        return true;
      } else {
        
        setError(response.message || 'Login failed');
        return false;
      }
    } catch (err: any) {
      console.error('Login error:', err);
      setError(err.message || 'An error occurred during login');
      return false;
    }
  };
  const logout = () => {
    authService.logout();
    setUser(null);
    setError(null);
    navigate('/login');
  };

  const clearError = () => setError(null);

  const value = {
    user,
    error,
    loading,
    isAuthenticated: authService.isAuthenticated(),
    login,
    logout,
    clearError
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Export the hook
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}